<template>
  <Transition name="fade">
    <aside
      v-if="show && !store.showWelcome"
      class="fixed bottom-0 left-0 right-0 z-30 px-6 py-4 sm:max-w-5xl sm:rounded-md md:left-auto"
    >
      <div
        class="absolute bottom-3 left-3 right-3 z-30 flex flex-col justify-between rounded-2xl bg-neutral-50/90 p-6px backdrop-blur-[20px] sm:w-[615px] md:bottom-16 md:left-auto md:right-9 lg:w-[790px]"
      >
        <h3 class="mb-4px text-heading-18-medium md:text-heading-22-medium">
          {{ $t('cookies.title') }}
        </h3>
        <i18n-t
          :keypath="isMd ? 'cookies.message' : 'cookies.shortMessage'"
          tag="p"
          class="mb-8px text-body-15-regular-px md:mb-12px"
          scope="global"
        >
          <template #privacyPolicy>
            <NuxtLink
              :to="$t('cookies.privacyLink')"
              class="text-orange underline hover:no-underline"
              noPrefetch
            >
              {{ $t('cookies.privacyPolicy') }}
            </NuxtLink>
          </template>
        </i18n-t>
        <div
          class="flex w-full flex-col items-center gap-6 md:flex-row md:justify-between md:gap-3"
        >
          <NuxtLink
            class="order-2 cursor-pointer text-body-15-regular-px underline hover:no-underline md:order-1 md:text-button-20-regular"
            @click="() => (showPreferences = true)"
          >
            {{ t('cookies.preferences') }}
          </NuxtLink>
          <div class="order-1 flex w-full flex-col gap-2 sm:flex-row md:order-2 md:w-fit">
            <Button
              class="!w-full !text-main-menu md:!w-fit"
              :label="$t('cookies.acceptAll')"
              @click="onAccept"
            />
            <Button
              class="!w-full !text-main-menu md:!w-fit"
              :label="$t('cookies.decline')"
              @click="onDecline"
            />
          </div>
        </div>
      </div>

      <Modal
        :show="showPreferences"
        class="max-w-[600px]"
        content-class="!px-4px !py-6px"
        variant="sheet"
        :showClose="false"
        @on-close="() => (showPreferences = false)"
      >
        <h3 class="mb-4px text-heading-18-medium md:text-heading-22-medium-px">
          {{ $t('cookies.preferences') }}
        </h3>
        <i18n-t
          :keypath="isMd ? 'cookies.message' : 'cookies.shortMessage'"
          tag="p"
          class="mb-12px text-body-15-regular-px"
          scope="global"
        >
          <template #privacyPolicy>
            <NuxtLink
              :to="$t('cookies.privacyLink')"
              class="text-orange underline"
              noPrefetch
              @click="showPreferences = false"
            >
              {{ $t('cookies.privacyPolicy') }}
            </NuxtLink>
          </template>
        </i18n-t>
        <p class="mb-6px text-heading-18-medium md:text-heading-20-medium-px">
          {{ $t('cookies.comply.manage') }}
        </p>
        <div class="mb-12px flex flex-col gap-5px">
          <div v-for="item in preferences" :key="item.type" class="relative flex gap-2px">
            <FormToggle
              v-model="state[item.type]"
              :name="item.type"
              :isDisabled="item.isRequired"
            />
            <div
              class="flex cursor-default items-center gap-2px text-body-18-regular-mobile md:text-body-20-regular-px"
            >
              {{ item.title }}
              <Tooltip popperClass="info-tooltip">
                <Icon name="info-general" class="h-4px w-4px cursor-pointer" />
                <template #popper>
                  <div class="flex flex-col gap-1">
                    <div class="text-heading-20-medium-px">{{ item.title }}</div>
                    <div class="text-body-15-regular-px text-neutral-800">
                      {{ item.description }}
                    </div>
                  </div>
                </template>
              </Tooltip>
            </div>
          </div>
        </div>
        <Button :label="$t('cookies.allowSelection')" :scalable="false" @click="onSave" />
      </Modal>
    </aside>
  </Transition>
</template>

<script setup lang="ts">
import { Tooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'
import '~/assets/css/tooltips.css'
import { useModalStore } from '~/stores/ModalStore'
import screens from '#tailwind-config/theme/screens'
import { LocalStorageKey } from '~/types/localStorage'

enum CookieType {
  Necessary = 'necessary',
  Performance = 'performance',
  Functional = 'functional',
  Targeting = 'targeting'
}

const { t } = useI18n()
const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
const store = useModalStore()

const show = ref(false)
const showPreferences = ref(false)
const preferences = ref([
  {
    type: CookieType.Necessary,
    title: t('cookies.comply.preferences.necessary.title'),
    description: t('cookies.comply.preferences.necessary.description'),
    isRequired: true
  },
  {
    type: CookieType.Performance,
    title: t('cookies.comply.preferences.performance.title'),
    description: t('cookies.comply.preferences.performance.description')
  },
  {
    type: CookieType.Functional,
    title: t('cookies.comply.preferences.functional.title'),
    description: t('cookies.comply.preferences.functional.description')
  },
  {
    type: CookieType.Targeting,
    title: t('cookies.comply.preferences.targeting.title'),
    description: t('cookies.comply.preferences.targeting.description')
  }
])
const result = ref('Waiting for user interaction...')

const state = reactive({
  [CookieType.Necessary]: true,
  [CookieType.Performance]: false,
  [CookieType.Functional]: false,
  [CookieType.Targeting]: false
})

onMounted(() => {
  setTimeout(() => {
    show.value = !localStorage.getItem(LocalStorageKey.CookieConsent)
  }, 1000)
})

const onDecline = () => {
  const accepted = [CookieType.Necessary]
  result.value = `User accepted the following cookies 📋: ${accepted.join(', ')}`
  localStorage.setItem(LocalStorageKey.CookieConsent, JSON.stringify(accepted))
  show.value = false
  // eslint-disable-next-line no-console
  console.log(result.value)
}

const onAccept = () => {
  const accepted = Object.values(CookieType)
  result.value = `User accepted all cookies. 👍: ${accepted.join(', ')}`
  localStorage.setItem(LocalStorageKey.CookieConsent, JSON.stringify(accepted))
  show.value = false
  // eslint-disable-next-line no-console
  console.log(result.value)
}

const onSave = () => {
  const accepted = Object.values(CookieType).filter(type => state[type])
  result.value = 'User accepted the following cookies 📋: ' + accepted.join(', ')
  localStorage.setItem(LocalStorageKey.CookieConsent, JSON.stringify(accepted))
  showPreferences.value = false
  show.value = false
  // eslint-disable-next-line no-console
  console.log(result.value)
}
</script>
