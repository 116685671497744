<template>
  <label
    :for="input?.id"
    class="relative inline-flex h-5px w-9px rounded-full bg-neutral-200 md:h-6px md:w-11px"
    :class="[
      isDisabled
        ? 'cursor-not-allowed bg-purple-700 opacity-60'
        : ['cursor-pointer', modelValue ? 'bg-purple' : 'bg-neutral-200']
    ]"
  >
    <input
      ref="input"
      v-uid
      v-bind="$attrs"
      class="sr-only"
      :checked="modelValue"
      :disabled="isDisabled"
      type="checkbox"
      @input="updateValue"
    />
    <div
      class="absolute left-0.5px top-0.5px block h-4px w-4px rounded-full bg-white transition-all duration-300 md:h-5px md:w-5px"
      :class="modelValue && 'translate-x-full'"
    ></div>
  </label>
</template>

<script setup lang="ts">
interface InputProps {
  modelValue: boolean
  isDisabled?: boolean
}

withDefaults(defineProps<InputProps>(), {
  modelValue: false,
  isDisabled: false
})

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits(['update:modelValue', 'on-change'])

const updateValue = (e: Event) => {
  const checked = (e.target as HTMLInputElement).checked
  emit('update:modelValue', checked)
  emit('on-change', checked)
}

const input = ref<null | HTMLElement>(null)
</script>
